import { template } from "lodash"

export const localization = {
  analytics: {
    exportVulns: {
      filename: "Отчёт по уязвимостям",

      periodTitle: "ПЕРИОД:",
      startDateText: "Начальная дата",
      endDateText: "Конечная дата",

      companyTitle: "Компания:",
      employeesText: "Количество сотрудников",
      employeesPlaceholder: "Кол-во сотрудников...",

      assets: "АКТИВЫ:",

      objectTypeTitle: "Тип объектов",
      webAppText: "Веб-приложение",
      apiText: "API",
      mobileAppText: "Мобильное приложение",
      networkDeviceText: "Сетевое устройство",
      serverText: "Сервер",
      armText: "АРМ",
      wifiText: "Wi-Fi",
      socialEngineeringText: "Социальная инженерия",
      desktopAppText: "Десктопное приложение",
      sourceCodeText: "Исходный код",
      externalText: "Неизвестное устройство (внешний ip)",
      internalText: "Неизвестное устройство (внутренний ip)",
      otherText: "Другой объект",

      vulnTypeTitle: "Тип уязвимостей",
      criticalVulnType: "Критические",
      highVulnType: "Высокие",
      mediumVulnType: "Средние",
      lowVulnType: "Низкие",
      infoVulnType: "Информационные",

      testConditionsTitle: "УСЛОВИЯ ПРОВЕДЕНИЯ ТЕСТИРОВАНИЯ:",

      methodTestTitle: "Метод тестирования",
      blackboxText: "Blackbox",
      greyboxText: "Greybox",

      modelAttackerTitle: "Модель атакующего",
      modelExternalText: "Внешний",
      modelInternalText: "Внутренний",

      workTypeTitle: "Вид работ",
      instrumentScanText: "Сканирование",
      securityAnalysisText: "Анализ защищенности",
      pentestText: "Пентест",
    },
    exportProject: {
      filename: "Отчёт по проекту",

      title: "Проект:",
      nameProjectText: "Название проекта",
      nameProjectPlaceholder: "Название проекта...",
    },
    import: {
      filename: "Отчёт",

      title: "Импорт файлов",
      excelText: "Excel",
      docxText: "Word",
      snapshotsText: "Снимок экрана",
    },
    title: "Генерация аналитического отчета по заданным параметрам",
    sendButtonText: "Отправить",
  },
  common: {
    project: "Проекты",
    analytics: "Аналитика",
    pentesters: "Пентестеры",

    present: "Присутствует",
    absent: "Отсутствует",

    backButtonText: "Назад",
    createButtonText: "Создать",
    changeButtonText: "Изменить",
    deleteButtonText: "Удалить",
    addVulnInKillChain: "Добавить в KillChain",
    exportTableButtonText: "Экспорт таблицы",
    restoreButtonText: "Восстановить",
    resetButtonText: "Сбросить",

    deleteFilterText: "Удалённые:",
    killChainFilterText: "Kill Chain:",
    myScopeFilterText: "Только мои:",

    secondaryInfoTitle: "Дополнительная информация:",
    stateInfoTitle: "Состояние:",

    closeAlt: "Закрыть",

    primaryNumberTextHelper: "Мин. кол-во",
    secondaryNumberTextHelper: "Макс. кол-во",
    primaryDateTextHelper: "От",
    secondaryDateTextHelper: "До",
  },
  settings: {
    title: "Ваши настройки",

    nameText: "Имя пользователя",
    namePlaceholder: "Имя...",
    emailText: "Ваш e-mail",
    emailPlaceholder: "E-mail...",
    roleText: "Ваша роль",
    rolePlaceholder: "Роль...",
    descriptionText: "Описание роли",
    descriptionPlaceholder: "Описание...",

    backButtonText: "Вернуться",
    logOutButtonText: "Выйти",
  },
  login: {
    title: "Комплексная система прогнозирования атак «FAVN»",

    logInTitle: "Войти",

    emailText: "E-mail",
    emailPlaceholder: "Введите e-mail...",
    passwordText: "Пароль",
    passwordPlaceholder: "Введите пароль...",

    logInButtonText: "Войти",
  },
  customer: {
    filename: "Заказчики",

    filters: {
      customerNameText: "Заказчик:",
      customerNamePlaceholder: "Поиск по заказчику...",
      innText: "ИНН:",
      innPlaceholder: "Поиск по ИНН...",
      employeesText: "Количество сотрудников:",
      employeesPlaceholder: "Поиск по количеству...",
      typeText: "Тип заказчика:",
      typePlaceholder: "Поиск по типу...",
    },
    tabs: {
      allTitle: "Все заказчики",
    },

    createButtonText: "Создать заказчика",
    notificationTitle: "Заказчик",

    infoList: {
      title: "Данные заказчика:",

      nameText: "Наименование:",
      innText: "ИНН:",
    },
    secondaryInfoList: {
      employeesText: "Количество сотрудников:",
      typeText: "Тип заказчика:",
      additionalInfoText: "Доп. информация",
      additionalInfoPlaceholder: "Доп. информация...",
    },
    interactive: {
      title: "Взаимодействие с заказчиком:",
      infSystemButtonText: "Инф. системы",
      officeButtonText: "Офисы",
    },

    confirmText: "заказчика",
  },
  infSystem: {
    filename: "Инф. системы",

    filters: {
      nameText: "Наименование ИС:",
      namePlaceholder: "Поиск по наименованию...",
      webInterfaceText: "Адрес Web-интерфейса:",
      webInterfacePlaceholder: "Поиск по адресу...",
      securityLevelText: "Уровень защищенности:",
      securityLevelPlaceholder: "Поиск по уровню защищенности...",
      productText: "Продукт:",
      productPlaceholder: "Поиск по продукту...",
      productManagerText: "Руководитель продукта:",
      productManagerPlaceholder: "Поиск по руководителю...",
      contactPersonText: "Ответственный за ИБ:",
      contactPersonPlaceholder: "Поиск по ответственному...",
      availabilityInterfaceText: "Интерфейс доступности:",
    },
    tabs: {
      allTitle: "Все инф. системы",
    },
    createButtonText: "Создать инф. систему",
    notificationTitle: "Инф. система",

    infoList: {
      title: "Данные инф. системы:",

      nameText: "Наименование ИС:",
      availabilityInterfaceText: "Интерфейс доступности:",
      webInterfaceAddressText: "Адрес Web-интерфейса:",
      securityLevelText: "Уровень защищенности:",
    },
    secondaryInfoList: {
      productText: "Продукт:",
      productManagerText: "Руководитель продукта:",
      contactPersonText: "Ответственный за ИБ:",
    },
    interactive: {
      title: "Взаимодействие с инф. системой:",
    },

    confirmText: "инф. систему",
    confirmDataText: "инф. системы",
  },
  office: {
    filename: "Офисы",

    filters: {
      nameText: "Наименование офиса:",
      namePlaceholder: "Поиск по наименованию...",
      addressText: "Адрес:",
      addressPlaceholder: "Поиск по адресу...",
      responsibleText: "Ответственный за ИБ:",
      responsiblePlaceholder: "Поиск по ответственному...",
      securityLevelText: "Уровень защищенности:",
      securityLevelPlaceholder: "Поиск по уровню защищенности...",
      availabilityWifiText: "Наличие Wi-Fi:",
      availabilitySeparateInternetText: "Наличие отдельного интернета:",
    },
    tabs: {
      allTitle: "Все офисы",
    },
    createButtonText: "Создать офис",
    notificationTitle: "Офис",

    infoList: {
      title: "Данные офиса:",

      nameText: "Наименование офиса:",
      addressText: "Адрес:",
      availabilityWifiText: "Наличие Wi-Fi:",
      availabilitySeparateInternetText: "Наличие отдельного интернета:",
    },
    secondaryInfoList: {
      responsibleText: "Ответственный за ИБ:",
      securityLevelText: "Уровень защищенности:",
    },
    interactive: {
      title: "Взаимодействие с офисом:",
    },

    confirmText: "офис",
    confirmDataText: "офиса",
  },
  project: {
    filename: "Проекты",

    filters: {
      nameText: "Наименование проекта:",
      namePlaceholder: "Поиск по наименованию...",
      functionalCustomerText: "Название:",
      functionalCustomerPlaceholder: "Поиск по названию...",
      startDateText: "Дата начала:",
      endDateText: "Дата окончания:",
      gosOrderNumberText: "Номер гос. контракта:",
      gosOrderNumberPlaceholder: "Поиск по контракту...",
      gosOrderDateText: "Дата гос. контракта:",
      projectStatusText: "Статус проекта:",
      projectStatusPlaceholder: "Поиск по статусу...",
    },
    tabs: {
      allTitle: "Все проекты",
    },
    createButtonText: "Создать проект",
    notificationProjectTitle: "Проект",
    notificationTeamleadTitle: "Тимлид",
    notificationManagerTitle: "Менеджер",

    infoList: {
      title: "Данные проекта:",

      customerNameText: "Наименование проекта:",
      nameText: "Название:",
      functionalCustomerText: "Функциональный заказчик:",
      startDateText: "Дата начала:",
      endDateText: "Дата окончания:",
    },
    secondaryInfoList: {
      statusProjectText: "Статус проекта:",
      gosOrderNumberText: "Номер гос. контракта:",
      gosOrderDateText: "Дата гос. контракта:",
    },
    stateInfo: {
      manager: "Текущий менеджер:",
      teamlead: "Текущий тимлид:",
    },
    interactive: {
      title: "Взаимодействие с проектом:",
      objectsButtonText: "Объекты",
      managerAppointButtonText: "Назначить менеджера",
      managerDeleteButtonText: "Удалить менеджера",
      teamleadAppointButtonText: "Назначить тимлида",
      teamleadDeleteButtonText: "Удалить тимлида",
      exportProjectButtonText: "Экспорт отчёта по проекту",
    },

    confirmProjectText: "проект",
    confirmProjectDataText: "проекта",
    confirmManagerText: "менеджера",
    confirmTeamleadText: "тимлида",
  },
  object: {
    createButtonText: "Создать объект",
    appointPentesterButtonText: "Назначить пентестера",
    deletePentesterButtonText: "Удалить пентестера",
    deleteObjectsButtonText: "Удалить",
    vulnsButtonText: "Уязвимости",
    appointTemplatesButtonText: "Применить шаблон",
    importTableButtonText: "Импорт таблицы",

    filters: {
      projectNameText: "Проект:",
      objectTypeText: "Тип объекта:",
      objectTypePlaceholder: "Поиск по типу...",
      infSystemText: "Имя информационной системы:",
      infSystemPlaceholder: "Поиск по инф. системе...",
      officeText: "Название офиса:",
      officePlaceholder: "Поиск по офису...",
      appNameText: "Название:",
      appNamePlaceholder: "Поиск по названию:",
      platformTypeText: "Платформа:",
      platformTypePlaceholder: "Поиск по платформе...",
      ipAddressText: "IP адрес:",
      ipAddressPlaceholder: "Поиск по IP адресу...",
      ipExternalAddressText: "Внешний IP адрес:",
      ipInternalAddressText: "Внутренний IP адрес:",
      networkDeviceText: "Сетевое имя устройства:",
      networkDevicePlaceholder: "Поиск по имени...",
      domainNameText: "Доменное имя:",
      ssidText: "SSID (Имя сети):",
      ssidPlaceholder: "Поиск по SSID...",
      bssidText: "BSSID (Уникальный идентификатор сети):",
      bssidPlaceholder: "Поиск по BSSID...",
      engineeringTypeText: "Вид:",
      successCriterionText: "Критерий успеха:",
      successCriterionPlaceholder: "Поиск по критерию успеха...",
      programmingLanguageText: "Язык программирования:",
      programmingLanguagePlaceholder: "Поиск по языку...",
      numberRowsText: "Кол-во строк кода приложения:",
      numberRowsPlaceholder: "Поиск по строкам кода...",
      assignmentText: "Назначение:",
      attackerModelText: "Модель атакующего:",
      attackerModelPlaceholder: "Поиск по модели...",
      workTypeText: "Вид работ:",
      workTypePlaceholder: "Поиск по виду...",
      additionalInfoText: "Доп. информация:",
      testMethodText: "Метод тестирования:",
      blackboxText: "Blackbox:",
      greyboxText: "Greybox:",
      greyboxAndBlackboxText: "Blackbox, Greybox",
    },
    infoWebAppTitle: "Данные веб-приложения:",
    infoApiTitle: "Данные API:",
    infoMobileAppTitle: "Данные мобильного приложения:",
    infoNetworkDeviceTitle: "Данные сетевого устройства:",
    infoServerTitle: "Данные сервера:",
    infoArmTitle: "Данные АРМ:",
    infoWifiTitle: "Данные Wi-Fi:",
    infoSocialEngineeringTitle: "Данные социальной инженерии:",
    infoDesktopAppTitle: "Данные десктопного приложения:",
    infoSourceCodeTitle: "Данные исходного кода:",
    infoInternalTitle: "Данные внутреннего IP адреса:",
    infoExternalTitle: "Данные внешнего IP адреса:",
    infoOtherTitle: "Данные другого объекта:",

    pentestersTitle: "Пентестеры:",
    internalTitle: "Внутренний",

    interactiveWebAppTitle: "Взаимодействие с веб-приложением:",
    interactiveApiTitle: "Взаимодействие с API:",
    interactiveMobileAppTitle: "Взаимодействие с мобильным приложением:",
    interactiveNetworkDeviceTitle: "Взаимодействие с сетевым устройством:",
    interactiveServerTitle: "Взаимодействие с серверов:",
    interactiveArmTitle: "Взаимодействие с АРМ:",
    interactiveWifiTitle: "Взаимодействие с Wi-Fi:",
    interactiveSocialEngineeringTitle: "Взаимодействие с социальной инженерией:",
    interactiveDesktopAppTitle: "Взаимодействие с десктопным приложением:",
    interactiveSourceCodeTitle: "Взаимодействие с исходным кодом:",
    interactiveInternalTitle: "Взаимодействие с внутренним IP адресом:",
    interactiveExternalTitle: "Взаимодействие с внешним IP адресом:",
    interactiveOtherTitle: "Взаимодействие с другим объектом:",

    confirmPentesterText: "пентестер",
    confirmPentestersText: "пентестеров",
  },
  vuln: {
    filename: "Уязвимости",

    filters: {
      nameText: "Имя уязвимости:",
      namePlaceholder: "Поиск по имени...",
      locationText: "Расположение:",
      locationPlaceholder: "Поиск по расположению...",
      negativeConsequencesText: "Негативные последствия:",
      negativeConsequencesPlaceholder: "Поиск по последствию...",
      cvssScoreText: "CVSS оценка:",
      cvssScorePlaceholder: "Поиск по оценке...",
      vectorText: "Вектор:",
      vectorPlaceholder: "Поиск по вектору...",
      riskLevelText: "Уровень риска:",
      riskLevelPlaceholder: "Поиск по уровню риска...",
      procedureExploitingText: "Порядок эксплуатации:",
      procedureExploitingPlaceholder: "Поиск по эксплуатации...",
    },
    tabs: {
      allTitle: "Все уязвимости",
    },
    createButtonText: "Создать уязвимость",
    deleteButtonText: "Удалить уязвимости",
    notificationTitle: "Уязвимость",

    infoList: {
      title: "Данные уязвимости:",
      killChain: "KillChain",
      includeIn: "Входит в",
      nameText: "Имя уязвимости:",
      locationText: "Расположение:",
      negativeConsequencesText: "Негативные последствия:",
      procedureExploitingText: "Порядок эксплуатации:",
      cveIdText: "СVE ID:",
      cweIdText: "СWE ID:",
    },
    secondaryInfoList: {
      cvssScoreText: "CVSS оценка:",
      riskLevelText: "Уровень риска:",
      descriptionText: "Описание:",
      causeOccurrenceText: "Причина возникновения",
      recommendationsText: "Рекомендации:",
    },
    stateInfo: {
      pentester: "Текущий пентестер:",
    },
    cvssInfo: {
      title: "CVSS",

      AV: "CVSS-AV:",
      AC: "CVSS-AC:",
      PR: "CVSS-PR:",
      UI: "CVSS-UI:",
      S: "CVSS-S:",
      C: "CVSS-C:",
      I: "CVSS-I:",
      A: "CVSS-A:",
    },
    interactive: {
      title: "Взаимодействие с уязвимостью:",
      screenshots: "Скриншоты:",
      objectsButtonText: "Объекты",
      deleteScreenshotsText: "Удалить скриншоты",
    },

    confirmVulnText: "уязвимость",
    confirmVulnDataText: "уязвимости",
    confirmScreenshotText: "скриншот",
    confirmScreenshotsText: "скриншоты",
  },
  error: {
    title: "Ой, что-то пошло не так!",
    commonPageButtonText: "Перейти на главную",
    reloadPageButtonText: "Перезагрузить страницу",
  },
  filters: {
    openButtonText: "Фильтры",
    resetButtonText: "Сбросить",
    searchButtonText: "Поиск",
  },
  archive: {
    title: "[УДАЛЕНО]",
    imgAlt: "Архив",
  },
  calendar: {
    placeholder: "Введите дату...",
  },
  header: {
    logoAlt: "Логотип",
    title: "Управление уязвимостями",
    optionsAlt: "Настройки",
  },
  logs: {
    hideButtonText: "Скрыть",
    openButtonText: "Раскрыть",
  },
  navbar: {
    projects: "Проекты",
    customers: "Заказчики",
    customer: "Заказчик",
    analytics: "Аналитика",
    templates: "Шаблоны",
    object: "Отчет",
  },
  notification: {
    changeText: "был(а) успешно изменён(а).",
    createText: "был(а) успешно создан(а).",
    uploadText: "был(а) успешно загружен(а).",
    loginText: "Вы успешно авторизированны.",
    logoutText: "Вы успешно вышли.",
    appointText: "был(а) успешно назначен(а).",
    deleteText: "был(а) успешно удалён(а).",

    authorizationDataError: "Данные для авторизации были не корректны. Попробуйте ещё раз.",
    authorizationError: "Вы не авторизированны.",
    roleError: "У вас недостаточно прав на выполнение данной операции.",
    pageEmptyError: "Данной страницы не найдено.",
    conflictError: "Отклонено из-за конфликта значений. Попробуйте ещё раз.",
    dataIncorrectError: "Данные были не корректны. Попробуйте ещё раз.",
    serverError: "Ошибка на сервере. Попробуйте ещё раз.",
  },
  pagination: {
    amount: "Количество:",
    allTitle: "Всего",
    arrowLeftAlt: "Влево",
    arrowRightAlt: "Вправо",
  },
  modals: {
    createButtonText: "Создать",
    appointButtonText: "Назначить",
    resetButtonText: "Сбросить",
    nameText: "Наименование",
    additionalInfoText: "Доп. информация",
    additionalInfoPlaceholder: "Доп. информация...",

    customer: {
      createTitle: "Создать заказчика",
      changeTitle: "Изменить заказчика",

      customerNameText: "Наименование",
      customerNamePlaceholder: "Наименование...",
      innText: "ИНН",
      innPlaceholder: "ИНН...",
      employeesText: "Количество сотрудников",
      employeesPlaceholder: "Количество сотрудников...",
      typeText: "Тип заказчика",
      typePlaceholder: "Тип заказчика...",
    },
    infSystem: {
      createTitle: "Создать инф. систему",
      changeTitle: "Изменить инф. систему",

      nameText: "Наименование ИС",
      namePlaceholder: "Наименование ИС...",
      webInterfaceText: "Адрес Web-интерфейса",
      webInterfacePlaceholder: "Адрес Web-интерфейса...",
      securityLevelText: "Уровень защищенности",
      securityLevelPlaceholder: "Уровень защищенности...",
      productText: "Продукт",
      productPlaceholder: "Продукт...",
      productManagerText: "Руководитель продукта",
      productManagerPlaceholder: "Руководитель продукта...",
      contactPersonText: "Ответственный за ИБ",
      contactPersonPlaceholder: "Ответственный за ИБ...",
      availabilityInterfaceText: "Интерфейс доступности",
    },
    office: {
      createTitle: "Создать офис",
      changeTitle: "Изменить офис",

      nameText: "Наименование офиса",
      namePlaceholder: "Наименование офиса...",
      addressText: "Адрес",
      addressPlaceholder: "Адрес...",
      responsibleText: "Ответственный за ИБ",
      responsiblePlaceholder: "Ответственный за ИБ...",
      securityLevelText: "Уровень защищенности",
      securityLevelPlaceholder: "Уровень защищенности...",
      availabilityWifiText: "Наличие Wi-Fi",
      availabilitySeparateInternetText: "Наличие отдельного интернета",
    },
    project: {
      createTitle: "Создать проект",
      changeTitle: "Изменить проект",
      customerNameText: "Заказчик",
      customerNamePlaceholder: "Заказчик...",
      nameText: "Наименование проекта",
      namePlaceholder: "Наименование проекта...",
      functionalCustomerText: "Функциональный заказчик",
      functionalCustomerPlaceholder: "Функциональный заказчик...",
      startDateText: "Дата начала",
      endDateText: "Дата окончания",
      projectStatusText: "Статус проекта",
      projectStatusPlaceholder: "Статус проекта...",
      gosOrderNumberText: "Номер гос. контракта",
      gosOrderNumberPlaceholder: "Номер гос. контракта...",
      gosOrderDateText: "Дата гос. контракта",
    },
    objects: {
      createObjectTitle: "Создать объект",

      createWebAppTitle: "Создать веб-приложение",
      createApiTitle: "Создать API",
      createMobileAppTitle: "Создать мобильное приложение",
      createNetworkDeviceTitle: "Создать сетевое устройство",
      createServerTitle: "Создать сервер",
      createArmTitle: "Создать АРМ",
      createWifiTitle: "Создать Wi-Fi",
      createSocialEngineeringTitle: "Создать социальная инженерия",
      createDesktopAppTitle: "Создать десктопное приложение",
      createSourceCodeTitle: "Создать исходный код",
      createExternalTitle: "Создать внешний IP адрес",
      createInternalTitle: "Создать внутренний IP адрес",
      createOtherTitle: "Создать другой объект",

      webAppResetButtonText: "веб-приложения",
      mobileAppResetButtonText: "мобильного приложения",
      networkDeviceResetButtonText: "сетевого устройства",
      serverResetButtonText: "сервера",
      socialEngineeringResetButtonText: "социальной инженерии",
      desktopAppResetButtonText: "десктопного приложения",
      sourceCodeResetButtonText: "исходного кода",
      internalResetButtonText: "внутреннего IP адреса",
      externalResetButtonText: "внешнего IP адреса",
      otherResetButtonText: "другого объекта",

      objectTypeText: "Тип объекта",
      objectTypePlaceholder: "Тип объекта...",
      groupTypeText: "Выберите группировку",
      groupTypePlaceholder: "Выберите группировку...",
      infSystemText: "Имя информационной системы",
      infSystemPlaceholder: "Имя информационной системы...",
      officeText: "Название офиса",
      officePlaceholder: "Название офиса...",
      appNameText: "Название",
      appNamePlaceholder: "Название...",
      platformTypeText: "Платформа",
      platformTypePlaceholder: "Платформа...",
      ipAddressText: "IP адрес",
      ipAddressPlaceholder: "IP адрес...",
      ipExternalAddressText: "Внешний IP адрес",
      ipExternalAddressPlaceholder: "Внешний IP адрес...",
      ipInternalAddressText: "Внутренний IP адрес",
      ipInternalAddressPlaceholder: "Внутренний IP адрес...",
      networkDeviceText: "Сетевое имя устройства",
      networkDevicePlaceholder: "Сетевое имя устройства...",
      assignmentText: "Назначение",
      assignmentPlaceholder: "Назначение...",
      domainNameText: "Доменное имя",
      domainNamePlaceholder: "Доменное имя...",
      ssidText: "SSID (Имя сети)",
      ssidPlaceholder: "SSID...",
      bssidText: "BSSID (Уникальный идентификатор сети)",
      bssidPlaceholder: "BSSID...",
      engineeringTypeText: "Вид",
      engineeringTypePlaceholder: "Вид...",
      successCriterionText: "Критерий успеха",
      successCriterionPlaceholder: "Критерий успеха...",
      programmingLanguageText: "Язык программирования",
      programmingLanguagePlaceholder: "Язык программирования...",
      numberRowsText: "Кол-во строк кода приложения",
      numberRowsPlaceholder: "Кол-во строк кода приложения...",
      attackerModelText: "Модель атакующего",
      attackerModelPlaceholder: "Модель атакующего...",
      workTypeText: "Вид работ",
      workTypePlaceholder: "Вид работ...",
      greyboxText: "Greybox",
      blackboxText: "Blackbox",

      webAppButtonText: "Веб-приложение",
      apiButtonText: "API",
      mobileAppButtonText: "Мобильное приложение",
      networkDeviceButtonText: "Сетевое устройство",
      serverButtonText: "Сервер",
      armButtonText: "АРМ",
      wifiButtonText: "Wi-Fi",
      socialEngineeringButtonText: "Социальная инженерия",
      desktopAppButtonText: "Десктопное приложение",
      sourceCodeButtonText: "Исходный код",
      externalButtonText: "Внешний IP адрес",
      internalButtonText: "Внутренний IP адрес",
      otherButtonText: "Другой",
    },
    vuln: {
      createTitle: "Создать уязвимость",
      changeTitle: "Изменить уязвимость",

      nameText: "Имя уязвимости",
      namePlaceholder: "Имя уязвимости...",
      cveIdText: "CVE ID (через запятую, например: CVE-1111-1111, CVE-2222-2222)",
      cveIdPlaceholder: "CVE ID...",
      cweIdText: "CWE ID (через запятую, например: CWE-1, CWE-2)",
      cweIdPlaceholder: "CWE ID...",
      locationText: "Расположение",
      locationPlaceholder: "Расположение...",
      descriptionText: "Описание",
      descriptionPlaceholder: "Описание...",
      causeOccurrenceText: "Причина возникновения",
      causeOccurrencePlaceholder: "Причина возникновения...",
      negativeConsequencesText: "Негативные последствия",
      negativeConsequencesPlaceholder: "Негативные последствия...",

      attackVectorText: "Attack Vector (AV)",
      attackVectorPlaceholder: "Attack Vector (AV)...",
      attackComplexityText: "Attack Complexity (AC)",
      attackComplexityPlaceholder: "Attack Complexity (AC)...",
      privilegesRequiredText: "Privileges Required (PR)",
      privilegesRequiredPlaceholder: "Privileges Required (PR)...",
      userInteractionText: "User Interaction (UI)",
      userInteractionPlaceholder: "User Interaction (UI)...",
      scopeText: "Scope (S)",
      scopePlaceholder: "Scope (S)...",
      confidentialityText: "Confidentiality (C)",
      confidentialityPlaceholder: "Confidentiality (C)...",
      integrityText: "Integrity (I)",
      integrityPlaceholder: "Integrity (I)...",
      availabilityText: "Availability (A)",
      availabilityPlaceholder: "Availability (A)...",

      vectorString: "Vector String",
      vector: "выберите все значения, чтобы сгенерировать вектор",

      cvssScoreText: "CVSS оценка:",
      cvssScorePlaceholder: "Поиск по оценке...",
      vectorText: "Вектор:",
      vectorPlaceholder: "Поиск по вектору...",
      riskLevelText: "Уровень риска:",
      riskLevelPlaceholder: "Поиск по уровню риска...",

      procedureExploitingText: "Порядок эксплуатации",
      procedureExploitingPlaceholder: "Порядок эксплуатации...",
      recommendationsText: "Рекомендация",
      recommendationsPlaceholder: "Рекомендация...",
      screenshotsText: "Снимок экрана",
      screenshotsPlaceholder: "Снимок экрана...",
    },
    export: {
      title: "Экспорт",
      pdfButtonText: "PDF",
      docxButtonText: "Word (Docx)",
      excelButtonText: "Excel",
    },
    confirm: {
      deleteHeaderTitle: "Удалить",
      changeHeaderTitle: "Изменить",
      resetDataHeaderTitle: "Cбросить данные",
      createHeaderTitle: "Создать",
      appointHeaderTitle: "Назначить",
      restoreHeaderTitle: "Восстановить",

      description: "Вы действительно хотите",

      confirmButtonText: "Подтвердить",
      cancelButtonText: "Отмена",
    },
    roles: {
      appointManagerTitle: "Назначить менеджера",
      appointTeamleadTitle: "Назначить тимлида",
      appointPentesterTitle: "Назначить пентестера",
      deletePentesterTitle: "Удалить пентестера",
    },
  },
}
