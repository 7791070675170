import React, { ChangeEvent, FC, useEffect, useState } from "react"

import { useLocation, useParams } from "react-router-dom"

import classNames from "classnames"
import fp from "lodash/fp"

import cn from "classnames"

import {
  attackComplexityPopupItems,
  attackVectorPopupItems,
  availabilityPopupItems,
  confidentialityPopupItems,
  integrityPopupItems,
  negativeConsequencesTypes,
  prepareRiskLevelToRu,
  privilegesRequiredPopupItems,
  scopePopupItems,
  userInteractionPopupItems,
  vulnStatusPopupItem,
} from "../../../constants/vulns"
import { useAppDispatch } from "../../../hooks/useAppDispatch"
import { useAppSelector } from "../../../hooks/useAppSelector"
import { useShowPopup } from "../../../hooks/useShowPopup"
import {
  changeVuln,
  updateVulnScreenshotsDescription,
  uploadScreenshotsAndDescriptions,
  uploadVulnScreenshots,
} from "../../../store/vulns/vulnsAsync"
import {
  selectVulnById,
  selectVulnErrors,
  selectVulns,
} from "../../../store/vulns/vulnsSelectors"
import { IGetScreenshotsList, IVuln } from "../../../store/vulns/vulnsTypes"
import { validateVuln } from "../../../utils/validate/validateVuln"
import Button from "../../button/Button"
import { InputTypeEnum } from "../../input/InputTypes"
import InputForm from "../../inputForm/InputForm"
import { IPopupItem } from "../../popup/PopupTypes"
import ConfirmModal from "../general/confirm/ConfirmModal"
import { ModalTypes } from "../general/confirm/ConfirmTypes"
import Modal from "../Modal"
import styles from "../Modal.module.scss"
import { IModalProps } from "../ModalTypes"

import { OBJECT_TYPES } from "../../../constants/objects"
import { ButtonTypeEnum } from "../../button/ButtonTypes"
import Text from "../../text/Text"
import Title from "../../title/Title"

import { preparedVulnCvssItems } from "../../../utils/prepare/preparedVulnCvssItems"

import { TitleVariantEnum } from "../../title/TitleTypes"

import { TextVariantEnum } from "../../text/TextTypes"

import { localization } from "../../../localization/localization"

import { ImageCard } from "../../../entities/ui/imageCard"
import { NavButton } from "../../../shared"
import {
  deleteScreenshot,
  resetTemplateScreenshots,
  setScreenshotBase64Data,
  setScreenshotDescription,
  setScreenshotSequence,
  setTemplateScreenshot,
} from "../../../store/vulns/vulnsSlice"
import style from "../applyTemplates/ApplyTemplates.module.scss"
import { IScreenshotDescriptions } from "../applyTemplates/applyTemplatesTypes"

import { calculateCvss } from "./utils/calculateCvss"
import { resetVulnData } from "./utils/resetVulnData"

interface IChangeVulnProps extends IModalProps {
  objectType?: string
  currentScreenshots?: IGetScreenshotsList[]
}

interface IScreenshotData {
  [id: string]: {
    src: string
    description: string
    isChanged: boolean
    sequence: number
  }
}

const ChangeVuln: FC<IChangeVulnProps> = ({
  isModalVisible,
  setModalVisible,
  currentObjectId,
  objectType,
  currentScreenshots,
}) => {
  const dispatch = useAppDispatch()

  const [isActiveTemplateTab, setIsActiveTemplateTab] = useState(true)
  const [screenshotsData, setScreenshotsData] = useState<IScreenshotData>({})
  const { templateScreenshots } = useAppSelector(selectVulns)

  const { vulnId, projectId, objectId } = useParams()

  const { allVulns } = useAppSelector(selectVulns)

  const { showPopupHandler } = useShowPopup()

  const {
    location_error,
    vulnerability_name_error,
    description_error,
    cause_of_occurrence_error,
    procedure_exploiting_error,
    recommendation_error,
    negative_consequences_error,
    cve_id_error,

    attack_vector_error,
    attack_complexity_error,
    privileges_required_error,
    user_interaction_error,
    integrity_error,
    availability_error,
    confidentiality_error,
    scope_error,
  } = useAppSelector(selectVulnErrors)

  const vulnState: IVuln = useAppSelector(selectVulnById)

  const [vuln, setVuln] = useState<IVuln>(vulnState)

  const [cveId, setCveId] = useState<string>(vuln.cve_id?.join(", ") || "")
  const [cweId, setCweId] = useState<string>(vuln.cwe_id?.join(", ") || "")
  const [selectedNegativeOption, setSelectedNegativeOption] =
    useState<any>(null)

  const [screenshots, setScreenshots] = useState<FileList | any[]>()

  const [cvssAV, setCvssAV] = useState<IPopupItem>({
    text: "",
    value: "",
  })
  const [cvssAC, setCvssAC] = useState<IPopupItem>({
    text: "",
    value: "",
  })
  const [cvssPR, setCvssPR] = useState<IPopupItem>({
    text: "",
    value: "",
  })
  const [cvssUI, setCvssUI] = useState<IPopupItem>({
    text: "",
    value: "",
  })
  const [cvssS, setCvssS] = useState<IPopupItem>({
    text: "",
    value: "",
  })
  const [cvssC, setCvssC] = useState<IPopupItem>({
    text: "",
    value: "",
  })
  const [cvssI, setCvssI] = useState<IPopupItem>({
    text: "",
    value: "",
  })
  const [cvssA, setCvssA] = useState<IPopupItem>({
    text: "",
    value: "",
  })

  const [isChangeVulnModal, setChangeVulnModal] = useState<boolean>(false)
  const [isResetVulnDataModal, setResetVulnDataModal] = useState<boolean>(false)

  useEffect(() => {
    setVuln(vulnState)
    if (vulnState.cwe_id != undefined) {
      setCveId(vulnState.cwe_id?.join(", "))
    }
    if (vulnState.cve_id) {
      setCweId(vulnState.cve_id?.join(", "))
    }
  }, [vulnState])

  useEffect(() => {
    if (vulnState.cvss_vector) {
      preparedVulnCvssItems(
        vulnState.cvss_vector,
        setCvssAV,
        setCvssAC,
        setCvssPR,
        setCvssUI,
        setCvssS,
        setCvssC,
        setCvssI,
        setCvssA
      )
    }
  }, [vulnState.cvss_vector])

  useEffect(() => {
    if (
      vulnState.negative_consequences &&
      vulnState.negative_consequences.length > 0
    ) {
      const prepareNegativeConsequences: any[] = []

      vulnState.negative_consequences.forEach((currentNegative) => {
        const negativeItem = negativeConsequencesTypes.find(
          (negative) => negative.value === currentNegative
        )

        if (negativeItem) {
          prepareNegativeConsequences.push(negativeItem)
        }
      })

      setSelectedNegativeOption(prepareNegativeConsequences)
    }

    setCveId(vulnState.cve_id?.join(", ") || "")
    setCweId(vulnState.cwe_id?.join(", ") || "")
  }, [dispatch, objectId, projectId, , vulnId, vulnState, allVulns.length])

  useEffect(() => {
    calculateCvss(
      vuln,
      setVuln,
      cvssAV,
      cvssAC,
      cvssPR,
      cvssUI,
      cvssS,
      cvssC,
      cvssI,
      cvssA
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvssAV, cvssAC, cvssPR, cvssUI, cvssS, cvssC, cvssI, cvssA])

  const changeVulnHandler = () => {
    const cvssItems = [
      cvssAV,
      cvssAC,
      cvssPR,
      cvssUI,
      cvssS,
      cvssC,
      cvssI,
      cvssA,
    ]

    const isValidate = validateVuln(
      vuln,
      dispatch,
      cvssItems,
      allVulns,
      cveId,
      cweId
    )

    const screenshotIds = Object.keys(screenshotsData)
    if (
      screenshotIds.length > 0 &&
      projectId &&
      vulnId &&
      objectId &&
      objectType &&
      descriptionIsChanged
    ) {
      for (const id of screenshotIds) {
        dispatch(
          updateVulnScreenshotsDescription({
            projectId,
            vulnId,
            objectId,
            objectType,
            screenId: id,
            fullScreenId: screenshotsData[id].description,
            sequence: screenshotsData[id].sequence,
          })
        )
      }
    }

    if (templateScreenshots && projectId && objectType && objectId && vulnId) {
      dispatch(
        uploadScreenshotsAndDescriptions({
          projectId,
          objectType,
          objectId,
          vulnId,
          screenshotData: templateScreenshots[objectId],
        })
      )
    }

    if (isValidate && projectId && objectId && objectType && vulnId) {
      const vulnSendData = {
        ...vuln,
        cve_id: cveId.split(", ").map((id) => id.trim()),
        cwe_id: cweId.split(", ").map((id) => id.trim()),
        is_killchain: vuln.is_killchain,
      }

      const isEqual = fp.isEqual(
        {
          ...vulnSendData,
          cvss_score: vulnSendData.cvss_score?.toString(),
          killchain_id: vulnSendData.killchain_id || null,
        },
        vulnState
      )
      !isEqual &&
        dispatch(
          changeVuln({
            vuln: vulnSendData,
            projectId,
            objectType,
            objectId,
            vulnId,
          })
        )

      if (screenshots && vulnId && objectType) {
        const data: {
          imagbase64: string
          sequence: number
          description: string
        }[] = []

        const keys = Object.keys(templateScreenshots[objectId])

        if (templateScreenshots && keys.length > 0) {
          for (const key of keys) {
            const screen = templateScreenshots[objectId][key]
            data.push({
              imagbase64: screen.base64data,
              description: screen.description,
              sequence: screen.sequence,
            })
          }
        }

        dispatch(
          uploadVulnScreenshots({
            files: data,
            vulnId,
            projectId,
            objectId,
            objectType,
          })
        )
      }

      setModalVisible(false)
    }
  }

  const resetVulnDataHandler = () => {
    resetVulnData(
      dispatch,
      setVuln,
      setCvssAV,
      setCvssAC,
      setCvssPR,
      setCvssUI,
      setCvssS,
      setCvssC,
      setCvssI,
      setCvssA,
      setCveId,
      setCweId
    )
    setModalVisible(false)
  }

  const onCvssAVPopupItemChange = (item: IPopupItem) => setCvssAV(item)
  const onCvssACPopupItemChange = (item: IPopupItem) => setCvssAC(item)
  const onCvssPRPopupItemChange = (item: IPopupItem) => setCvssPR(item)
  const onCvssUIPopupItemChange = (item: IPopupItem) => setCvssUI(item)
  const onCvssSPopupItemChange = (item: IPopupItem) => setCvssS(item)
  const onCvssCPopupItemChange = (item: IPopupItem) => setCvssC(item)
  const onCvssIPopupItemChange = (item: IPopupItem) => setCvssI(item)
  const onCvssAPopupItemChange = (item: IPopupItem) => setCvssA(item)

  const onNegativeConsequencesChange = (data: any) => {
    const prepareNegativeConsequences = data.map(({ value }: any) => value)

    setVuln({ ...vuln, negative_consequences: prepareNegativeConsequences })
    setSelectedNegativeOption(data)
  }

  const onConfirmChangeModalHandler = () =>
    setChangeVulnModal((prevState) => !prevState)
  const onConfirmResetModalHandler = () =>
    setResetVulnDataModal((prevState) => !prevState)
  const [descriptionIsChanged, setDescriptionIsChanged] = useState(false)

  const handleFileChange = (event: any) => setScreenshots(event.target.files)
  const handleScreenshotDescription = (
    e: ChangeEvent<HTMLTextAreaElement>,
    fileName: string | null
  ) => {
    if (fileName !== null && currentObjectId) {
      dispatch(
        setScreenshotDescription({
          description: e.target.value,
          fileName,
          objectId: currentObjectId,
        })
      )
    }
    setDescriptionIsChanged(true)
  }

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    id: string
  ) => {
    setScreenshotsData({
      ...screenshotsData,
      [id]: {
        ...screenshotsData[id],
        description: event.target.value,
        isChanged: true,
      },
    })
    setDescriptionIsChanged(true)
  }

  const handleDeleteScreenshot = (fileName: string) => {
    dispatch(deleteScreenshot({ objectId: currentObjectId, fileName }))
  }

  function readFile(file: File, objectId: string, fileName: string) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const dataBase64 = reader.result
      if (typeof dataBase64 === "string") {
        dispatch(
          setScreenshotBase64Data({
            objectId: objectId,
            fileName: fileName,
            base64Data: dataBase64,
          })
        )
      }
    }

    reader.onerror = function () {}
  }

  const getObjectScreenshotData = (files: FileList) => {
    const screenshotsList = []
    const descriptions: IScreenshotDescriptions = {}
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      const fileName = file?.name ? Date.now() + "_" + file.name : ""
      const currentScrennshotObject = { file: file, fileName: fileName }
      screenshotsList.push(currentScrennshotObject)
      descriptions[fileName] = " "
    }
    return {
      screenshotsList: screenshotsList,
      descriptions: descriptions,
    }
  }

  useEffect(() => {
    if (currentObjectId && templateScreenshots[currentObjectId]) {
      Object.keys(templateScreenshots[currentObjectId]).forEach((filename) => {
        const file = templateScreenshots[currentObjectId][filename].file
        readFile(file, currentObjectId, filename)
      })
    }
  }, [templateScreenshots, currentObjectId])

  useEffect(() => {
    dispatch(resetTemplateScreenshots())
    return () => {
      setDescriptionIsChanged(false)
    }
  }, [])
  const handlerPaste = (event: ClipboardEventInit) => {
    const files = event.clipboardData?.files
    if (files != undefined) {
      const screenshotsData = getObjectScreenshotData(files)
      screenshotsData.screenshotsList.forEach((fileData) => {
        if (fileData.file !== null && currentObjectId) {
          dispatch(
            setTemplateScreenshot({
              objectId: currentObjectId,
              fileName: fileData.fileName,
              image: fileData.file,
            })
          )
        }
      })
    }
  }

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)
        const fileName = file?.name ? Date.now() + "_" + file.name : ""
        if (file !== null && currentObjectId) {
          dispatch(
            setTemplateScreenshot({
              objectId: currentObjectId,
              fileName,
              image: file,
            })
          )
          readFile(file, currentObjectId, fileName)
        }
      }

      e.currentTarget.value = ""
    }
  }

  useEffect(() => {
    const screenshots: IScreenshotData = {}
    currentScreenshots?.forEach((screenshot) => {
      const id = screenshot.id
      screenshots[id] = {
        src: screenshot.id + ".jpg",
        description: screenshot.description,
        isChanged: false,
        sequence: screenshot.sequence,
      }
    })
    setScreenshotsData(screenshots)
  }, [currentScreenshots])

  const handleScreenshotSequence = (
    event: ChangeEvent<HTMLInputElement>,
    fileName?: string,
    id?: string
  ) => {
    if (id) {
      setScreenshotsData({
        ...screenshotsData,
        [id]: {
          ...screenshotsData[id],
          sequence: Number(event.target.value) || 0,

          isChanged: true,
        },
      })
      setDescriptionIsChanged(true)
    } else if (fileName) {
      dispatch(
        setScreenshotSequence({
          objectId: currentObjectId,
          fileName,
          sequence: event.target.value,
        })
      )
      setDescriptionIsChanged(true)
    }
  }

  return (
    <>
      <Modal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        title={localization.modals.vuln.changeTitle}
        onPaste={handlerPaste}
        isFullScreen={true}
        isNavBar={true}
      >
        <div className={cn(style["applyTemplatesModal_nav"])}>
          <NavButton
            text="Уязвимость"
            isActive={isActiveTemplateTab}
            onClick={() => setIsActiveTemplateTab(true)}
            buttonType="nav"
          />
          <NavButton
            text="Скриншоты"
            isActive={!isActiveTemplateTab}
            onClick={() => {
              setIsActiveTemplateTab(false)
            }}
            buttonType="nav"
          />
        </div>
        {isActiveTemplateTab ? (
          <div className={styles["modal-inputs"]}>
            <InputForm
              text={localization.modals.vuln.nameText}
              placeholder={localization.modals.vuln.namePlaceholder}
              value={vuln.name}
              errorMessage={vulnerability_name_error}
              onChange={(event) =>
                setVuln({ ...vuln, name: event.target.value })
              }
              required
            />
            <InputForm
              text={localization.modals.vuln.cveIdText}
              placeholder={localization.modals.vuln.cveIdPlaceholder}
              errorMessage={cve_id_error}
              value={cveId}
              onChange={(event) => setCveId(event.target.value)}
            />
            <InputForm
              text={localization.modals.vuln.cweIdText}
              placeholder={localization.modals.vuln.cweIdPlaceholder}
              value={cweId}
              onChange={(event) => setCweId(event.target.value)}
            />
            <InputForm
              text={localization.modals.vuln.locationText}
              placeholder={localization.modals.vuln.locationPlaceholder}
              value={vuln.location}
              errorMessage={location_error}
              onChange={(event) => {
                setVuln({ ...vuln, location: event.target.value })
              }}
              required
            />
            <InputForm
              text={localization.modals.vuln.descriptionText}
              placeholder={localization.modals.vuln.descriptionPlaceholder}
              value={vuln.description}
              errorMessage={description_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, description: event.target.value })
              }}
              required
              textarea
            />
            <InputForm
              text={localization.modals.vuln.causeOccurrenceText}
              placeholder={localization.modals.vuln.causeOccurrencePlaceholder}
              value={vuln.cause_of_occurrence}
              errorMessage={cause_of_occurrence_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, cause_of_occurrence: event.target.value })
              }}
              required
              textarea
            />
            <InputForm
              text={localization.modals.vuln.negativeConsequencesText}
              placeholder={
                localization.modals.vuln.negativeConsequencesPlaceholder
              }
              errorMessage={negative_consequences_error}
              value={selectedNegativeOption}
              onSelectChange={onNegativeConsequencesChange}
              options={negativeConsequencesTypes}
              isMulti
              required
            />
            <div className={styles["modal-calculator-wrapper"]}>
              <div className={styles["modal-calculator-panel"]}>
                <InputForm
                  text={localization.modals.vuln.attackVectorText}
                  placeholder={localization.modals.vuln.attackVectorPlaceholder}
                  errorMessage={attack_vector_error}
                  value={cvssAV.text || ""}
                  popupItems={attackVectorPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssAVPopupItemChange}
                  onChange={(event) => {
                    setCvssAV({ text: event.target.value, value: "" })
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.attackComplexityText}
                  placeholder={
                    localization.modals.vuln.attackComplexityPlaceholder
                  }
                  errorMessage={attack_complexity_error}
                  value={cvssAC.text || ""}
                  popupItems={attackComplexityPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssACPopupItemChange}
                  onChange={(event) => {
                    setCvssAC({ text: event.target.value, value: "" })
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.privilegesRequiredText}
                  placeholder={
                    localization.modals.vuln.privilegesRequiredPlaceholder
                  }
                  errorMessage={privileges_required_error}
                  value={cvssPR.text || ""}
                  popupItems={privilegesRequiredPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssPRPopupItemChange}
                  onChange={(event) => {
                    setCvssPR({ text: event.target.value, value: "" })
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.userInteractionText}
                  placeholder={
                    localization.modals.vuln.userInteractionPlaceholder
                  }
                  errorMessage={user_interaction_error}
                  value={cvssUI.text || ""}
                  popupItems={userInteractionPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssUIPopupItemChange}
                  onChange={(event) => {
                    setCvssUI({ text: event.target.value, value: "" })
                  }}
                  disabled
                  required
                />
              </div>
              <div className={styles["modal-calculator-panel"]}>
                <InputForm
                  text={localization.modals.vuln.scopeText}
                  placeholder={localization.modals.vuln.scopePlaceholder}
                  errorMessage={scope_error}
                  value={cvssS.text || ""}
                  popupItems={scopePopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssSPopupItemChange}
                  onChange={(event) => {
                    setCvssS({ text: event.target.value, value: "" })
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.confidentialityText}
                  placeholder={
                    localization.modals.vuln.confidentialityPlaceholder
                  }
                  errorMessage={confidentiality_error}
                  value={cvssC.text || ""}
                  popupItems={confidentialityPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssCPopupItemChange}
                  onChange={(event) => {
                    setCvssC({ text: event.target.value, value: "" })
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.integrityText}
                  placeholder={localization.modals.vuln.integrityPlaceholder}
                  errorMessage={integrity_error}
                  value={cvssI.text || ""}
                  popupItems={integrityPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssIPopupItemChange}
                  onChange={(event) => {
                    setCvssI({ text: event.target.value, value: "" })
                  }}
                  disabled
                  required
                />
                <InputForm
                  text={localization.modals.vuln.availabilityText}
                  placeholder={localization.modals.vuln.availabilityPlaceholder}
                  errorMessage={availability_error}
                  value={cvssA.text || ""}
                  popupItems={availabilityPopupItems}
                  onClick={showPopupHandler}
                  onPopupChange={onCvssAPopupItemChange}
                  onChange={(event) => {
                    setCvssA({ text: event.target.value, value: "" })
                  }}
                  disabled
                  required
                />
              </div>
            </div>
            <div className={styles["modal-calculator-info"]}>
              <Text className={styles["modal-calculator-info-title"]}>
                {`${localization.modals.vuln.vectorString} - ${
                  vuln.cvss_vector
                    ? vuln.cvss_vector
                    : localization.modals.vuln.vector
                }`}
              </Text>
              {vuln.risk_level && (
                <div
                  className={classNames(
                    styles["modal-calculator-info-score"],
                    styles[`modal-calculator-info-score_${vuln.risk_level}`]
                  )}
                >
                  <Title variant={TitleVariantEnum.H1}>
                    {vuln.cvss_score !== null && vuln.cvss_score !== undefined
                      ? vuln.cvss_score?.toString()
                      : "?"}
                  </Title>
                  <Text
                    className={styles["modal-calculator-info-risk-level"]}
                    variant={TextVariantEnum.L}
                  >
                    {`(${prepareRiskLevelToRu[vuln.risk_level]})`}
                  </Text>
                </div>
              )}
            </div>
            <InputForm
              text={localization.modals.vuln.procedureExploitingText}
              placeholder={
                localization.modals.vuln.procedureExploitingPlaceholder
              }
              value={vuln.procedure_exploiting}
              errorMessage={procedure_exploiting_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, procedure_exploiting: event.target.value })
              }}
              required
              textarea
            />
            <InputForm
              text={localization.modals.vuln.recommendationsText}
              placeholder={localization.modals.vuln.recommendationsPlaceholder}
              value={vuln.recommendations}
              errorMessage={recommendation_error}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, recommendations: event.target.value })
              }}
              required
              textarea
            />

            <InputForm
              text={localization.modals.additionalInfoText}
              placeholder={localization.modals.additionalInfoPlaceholder}
              value={vuln.additional_info}
              onTextareaChange={(event) => {
                setVuln({ ...vuln, additional_info: event.target.value })
              }}
              textarea
            />
            <InputForm
              text={"Статус"}
              placeholder={"Статус..."}
              value={vuln.status}
              popupItems={vulnStatusPopupItem}
              onClick={showPopupHandler}
              onPopupChange={(item: IPopupItem) => {
                setVuln({ ...vuln, status: item.value })
              }}
            />
          </div>
        ) : (
          <div className={styles["modal-inputs"]}>
            {Object.keys(screenshotsData).length > 0 &&
              Object.keys(screenshotsData).map((id) => (
                <ImageCard
                  imageSrc={
                    "/filestore/media/screenshots/" + screenshotsData[id].src
                  }
                  imageName={screenshotsData[id].src}
                  sequence={screenshotsData[id].sequence}
                  onSequenceChange={(e) => {
                    handleScreenshotSequence(e, undefined, (id = id))
                  }}
                  onTextAreaChange={(e) => {
                    handleChangeDescription(e, id)
                  }}
                  textDescription={screenshotsData[id].description}
                  isDeleteButton={false}
                />
              ))}

            {currentObjectId &&
              templateScreenshots[currentObjectId] &&
              Object.keys(templateScreenshots[currentObjectId]).map(
                (fileName) => (
                  <ImageCard
                    imageSrc={
                      templateScreenshots[currentObjectId][fileName].base64data
                    }
                    imageName={fileName}
                    sequence={
                      templateScreenshots[currentObjectId][fileName].sequence
                    }
                    onSequenceChange={(e) =>
                      handleScreenshotSequence(e, (fileName = fileName))
                    }
                    textDescription={
                      templateScreenshots[currentObjectId][fileName].description
                    }
                    onDeleteHandler={() => {
                      handleDeleteScreenshot(fileName)
                    }}
                    onTextAreaChange={(event) => {
                      handleScreenshotDescription(event, fileName)
                    }}
                  />
                )
              )}
          </div>
        )}
        <div
          className={classNames(
            styles["modal-buttons"],
            styles["modal-buttons_between"]
          )}
        >
          <div style={{ flexGrow: 1, display: "flex" }}>
            {!isActiveTemplateTab && (
              <InputForm
                text={""}
                placeholder={localization.modals.vuln.screenshotsPlaceholder}
                type={InputTypeEnum.File}
                onChange={handleFileInputChange}
                multiple={true}
                isMulti={true}
              />
            )}
          </div>
          <div></div>
          <Button
            type={ButtonTypeEnum.Red}
            buttonText={localization.modals.resetButtonText}
            onClick={onConfirmResetModalHandler}
          />
          <Button
            buttonText={localization.common.changeButtonText}
            onClick={onConfirmChangeModalHandler}
          />
        </div>
      </Modal>
      <ConfirmModal
        isModalVisible={isChangeVulnModal}
        setModalVisible={setChangeVulnModal}
        text={localization.vuln.confirmVulnText}
        onConfirmClick={changeVulnHandler}
        type={ModalTypes.Change}
      />
      <ConfirmModal
        isModalVisible={isResetVulnDataModal}
        setModalVisible={setResetVulnDataModal}
        text={localization.vuln.confirmVulnDataText}
        onConfirmClick={resetVulnDataHandler}
        type={ModalTypes.Reset}
      />
    </>
  )
}

export default ChangeVuln
