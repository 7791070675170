import { IPagination } from "../client/clientTypes"


//TODO: Переписать функции с учетом union

type queriesType = Record<string, string | string[] | number | boolean | any>

export const getQueryParams = (
  pagination?: IPagination,
  filters?: queriesType,
  sortBy?: string,
) => {
  const result: string[] = []

  if (pagination?.limit) {
    result.push(`offset=${pagination.offset}&limit=${pagination.limit}`)
  }

  if (filters) {
    result.push(...getQueryKeyAndValues(filters))
  }

  if (sortBy !== undefined) {
    result.push(`${sortBy}`)
  }


  if (result.length === 0) {
    return ""
  } else {
    return `?${result.join("&")}`
  }


}

const getQueryKeyAndValues = (
  queries: { [key: string]: string | string[] | number | boolean },
): string[] => {
  const keys = Object.keys(queries)
  if (keys.length === 0) {
    return []
  } else {
    const result: string[] = []
    for (const key of keys) {
      const values = queries[key]

      if (Array.isArray(values)) {
        if (values.length > 0) {
          result.push(...values.map(el => `${key}=${el}`))

        }
      }
      else {
        if (values) {
          result.push(`${key}=${values}`)
        }
      }
    }
    return result
  }
} 