import { FC, useEffect, useMemo, useState } from "react"

import { Link } from "react-router-dom"

import Navbar from "../../components/navbar/Navbar"
import Tabs from "../../components/tabs/Tabs"
import { ILink } from "../../components/tabs/TabsTypes"
import Title from "../../components/title/Title"
import { TitleVariantEnum } from "../../components/title/TitleTypes"
import { DEFAULT_OFFSET_PAGINATION } from "../../constants/pages"
import { useAppDispatch } from "../../hooks/useAppDispatch"
import { useAppSelector } from "../../hooks/useAppSelector"
import { CollapseText, sortListType, TableComponent } from "../../shared"
import { getLoginHistory, getReportHistory } from "../../store/auth/authAsync"
import {
  selectLoginHistory,
  selectProfileData,
  selectReportHistory,
} from "../../store/auth/authSelectors"
import { BASE_URI } from "../../constants/auth"
import PaginationV2 from "../../shared/ui/PaginationV2/PaginationV2"
import {
  getDefaultSortParams,
  getNextSortParam,
  getSortString,
} from "../../shared/lib"

interface IReportTypeData {
  id: string
  report_type: string
  created_at: string
  project: string | JSX.Element
  file: JSX.Element
}

export const LogsPage: FC = () => {
  const dispatch = useAppDispatch()
  const loginHistory = useAppSelector(selectLoginHistory)
  const reportHistory = useAppSelector(selectReportHistory)
  const { role } = useAppSelector(selectProfileData)

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: DEFAULT_OFFSET_PAGINATION,
  })
  const [selectTab, setSelectTab] = useState<string>("auth")
  const [selectTabLinks, setSelectTabLinks] = useState<ILink[]>([])
  const [count, setCount] = useState<number>(0)

  const headersAuthRow = {
    login: { label: "Логин" },

    ip_address: { label: "IP" },
    created_at: { label: "Дата" },
    status: { label: "Статус" },
  }

  const headersReportRow = {
    report_type: { label: "Тип отчета" },
    created_at: { label: "Дата создания" },
    project: { label: "Проект/Параметры", isUnsorted: true },
    file: { label: "Файл отчета", isUnsorted: true },
  }

  const [sortParams, setSortParams] = useState<sortListType>(
    getDefaultSortParams(headersAuthRow)
  )

  const [sortReportParams, setSortReportParams] = useState<sortListType>(
    getDefaultSortParams(headersReportRow)
  )

  useEffect(() => {
    const currentTabLinks: ILink[] = [
      {
        name: "Авторизация",
        tabId: "auth",
      },
    ]

    if (role !== "pentester") {
      currentTabLinks.push({
        name: "Формирование отчетов",
        tabId: "report",
      })
    }

    setSelectTabLinks(currentTabLinks)
  }, [role])

  const reportData = useMemo<IReportTypeData[]>(
    () =>
      reportHistory.data.map((el) => {
        let fileLink = ""

        if (BASE_URI && el.dir_path) {
          fileLink = `${BASE_URI}/${el.dir_path}/${el.id}${el.file_extension}`
        }

        const paramsData: JSX.Element =
          el.report_type === "project" ? (
            <Link to={`${BASE_URI}/projects/${el.project?.id}`}>
              {el.project?.name}
            </Link>
          ) : (
            <CollapseText
              visibleText="Скрыть..."
              hiddenText="Показать параметры..."
            >
              {el.query_params || ""}
            </CollapseText>
          )

        return {
          id: el.id,
          report_type:
            el.report_type === "project"
              ? "Проект"
              : el.report_type === "object"
                ? "Объекты"
                : "Уязвимости",
          created_at: el.created_at.toLocaleString(),
          file: <a href={fileLink}>Скачать отчет</a>,
          project: paramsData,
        }
      }),
    [reportHistory.data]
  )

  useEffect(() => {
    setPagination({
      offset: 0,
      limit: DEFAULT_OFFSET_PAGINATION,
    })
  }, [selectTab])

  const onTabClick = (name: string) => setSelectTab(name)
  useEffect(() => {
    if (selectTab === "auth") {
      dispatch(
        getLoginHistory({
          offset: pagination.offset,
          limit: pagination.limit,
          sort_by: getSortString(sortParams),
        })
      )
    } else {
      dispatch(
        getReportHistory({
          offset: pagination.offset,
          limit: pagination.limit,
          sort_by: getSortString(sortReportParams),
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pagination, sortParams, sortReportParams])

  useEffect(() => {
    const count =
      selectTab === "auth" ? loginHistory.count : reportHistory.count
    setCount(count)
  }, [loginHistory, reportHistory, selectTab])

  const onPaginationHandle = (offset?: number, limit?: number) => {
    setPagination((current) => ({
      offset: offset === undefined ? current.offset : offset,
      limit: limit || current.limit,
    }))
  }

  const handleClickSort = (param: string) => {
    setSortParams(getNextSortParam(param, sortParams))
  }

  const handleClickSortReport = (param: string) => {
    setSortReportParams(getNextSortParam(param, sortReportParams))
  }

  return (
    <>
      <Navbar />
      <div
        style={{
          margin: "24px 100px 32px",
          backgroundColor: "white",
          padding: "24px",
        }}
      >
        <Title variant={TitleVariantEnum.H1}>Статистика</Title>
        <Tabs
          links={selectTabLinks}
          tabActive={selectTab}
          onClick={onTabClick}
        />
        {selectTab === "auth" && (
          <>
            <TableComponent
              sortParams={sortParams}
              headersRow={headersAuthRow}
              handleClickSort={handleClickSort}
              dataList={loginHistory.data}
              isNotLink
            />
          </>
        )}
        {selectTab === "report" && role !== "pentester" && reportData && (
          <>
            <TableComponent
              sortParams={sortReportParams}
              headersRow={headersReportRow}
              handleClickSort={handleClickSortReport}
              dataList={reportData}
              isNotLink
            />
          </>
        )}
        <PaginationV2
          pagination={pagination}
          count={count}
          setPagination={onPaginationHandle}
        />
      </div>
    </>
  )
}
