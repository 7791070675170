import classNames from "classnames"
import { FC, useEffect, useState } from "react"

import { NavLink, useLocation } from "react-router-dom"

import { ROUTES } from "../../router/routes"
import { useAppSelector } from "../../hooks/useAppSelector"
import { selectProfileData } from "../../store/auth/authSelectors"

import { LocationPath } from "../../entities"

import styles from "./Navbar.module.scss"
import { analyticsLinks, clientObjectLinks, customersLink, getCustomerLink, projectLink, templatesLink } from "./NavbarItems"

interface ILinkItemType {
  href: string
  title: string
  id: number
}


const Navbar: FC = () => {

  const location = useLocation()

  const { role, customer_id: customerID } = useAppSelector(selectProfileData)

  const [links, setLinks] = useState<ILinkItemType[]>([])

  useEffect(() => {

    if (role) {
      if (location.pathname === ROUTES.COMMON + ROUTES.ANALYTICS) {
        setLinks([
          analyticsLinks,
        ])

      } else if (role === "teamlead" || role === "pentester") {
        setLinks([
          projectLink,
          templatesLink,
        ])

      } else if (role === "client" && customerID) {
        setLinks([
          projectLink,
          getCustomerLink(customerID),
          clientObjectLinks,
        ])

      } else if (role === "client") {
        setLinks([
          projectLink,
          clientObjectLinks,
        ])

      } else {
        setLinks([
          projectLink,
          customersLink,
          templatesLink,

        ])

      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, role])

  return (<>

    <ul className={styles["navbar"]}>
      {links.map(({ href, title, id }) => (
        <li key={id} className={styles["navbar-item-wrapper"]}>
          <NavLink
            to={href}
            className={({ isActive }) => isActive ?
              classNames(styles["navbar-item_active"], styles["navbar-item"]) : styles["navbar-item"]
            }>
            {title}
          </NavLink>
        </li>
      ))}
    </ul>
    <LocationPath />
  </>

  )
}

export default Navbar
