import { localization } from "../../localization/localization"
import { ROUTES } from "../../router/routes"

export const projectLink = {
  href: ROUTES.COMMON + ROUTES.PROJECTS,
  title: localization.navbar.projects,
  id: 1,
}

export const customersLink = {
  href: ROUTES.COMMON + ROUTES.CUSTOMERS,
  title: localization.navbar.customers,
  id: 2,
}

export const templatesLink = {
  href: ROUTES.COMMON + ROUTES.TEMPLATES,
  title: localization.navbar.templates,
  id: 3,
}

export const analyticsLinks = {
  href: ROUTES.COMMON + ROUTES.ANALYTICS,
  title: localization.navbar.analytics,
  id: 4,
}

export const clientObjectLinks = {
  href: ROUTES.COMMON + ROUTES.REPORT,
  title: localization.navbar.object,
  id: 5,
}

export const getCustomerLink = (customerId: string) => ({
  href: ROUTES.COMMON + ROUTES.CUSTOMERS + "/" + customerId,
  title: localization.navbar.customer,
  id: 6,
})
