import { configureStore } from '@reduxjs/toolkit'

import { $api } from '../shared/api/baseApi'

import projectsReducer from './projects/projectsSlice'
import authReducer from './auth/authSlice'
import objectsReducer from './objects/objectsSlice'
import vulnsReducer from './vulns/vulnsSlice'
import customersReducer from './customers/customersSlice'
import analyticsReducer from './analytics/analyticsSlice'
import infSystemsReducer from './infSystems/infSystemsSlice'
import officesReducer from './offices/officesSlice'
import templateReducer from './templates/templatesSlice'
import uniqueVulns from './uniqueVulns/uniqueVulnsSlice'
import killchain from './killchains/killchainsSlice'
import client from './client/clientSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectsReducer,
    objects: objectsReducer,
    vulns: vulnsReducer,
    customers: customersReducer,
    analytics: analyticsReducer,
    infSystems: infSystemsReducer,
    offices: officesReducer,
    templates: templateReducer,
    uniqueVulns: uniqueVulns,
    killchain: killchain,
    client: client,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          api: $api,
        },
      },
    }),
})
