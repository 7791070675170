import React, { FC, useEffect, useMemo } from 'react'
import cn from 'classnames'

import { NavLink } from 'react-router-dom'

import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectProjectById } from '../../../store/projects/projectsSelectors'
import { selectObjects } from '../../../store/objects/objectsSelectors'
import { selectVulnById } from '../../../store/vulns/vulnsSelectors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { getBaseObjects } from '../../../store/objects/objectsAsync'
import { selectOfficeById } from '../../../store/offices/officesSelectors'
import { selectCustomerById } from '../../../store/customers/customersSelectors'
import { getCustomerById } from '../../../store/customers/customersAsync'
import { selectInfSystemById } from '../../../store/infSystems/infSystemsSelectors'
import { parseUrl } from '../lib/parseUrl'

import style from './LocationPath.module.scss'

export const LocationPath: FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectProjectById)
  const { base } = useAppSelector(selectObjects)
  const vuln = useAppSelector(selectVulnById)
  const office = useAppSelector(selectOfficeById)
  const customer = useAppSelector(selectCustomerById)
  const infSystem = useAppSelector(selectInfSystemById)

  const location = window.location.href
  const locationList = useMemo(
    () => parseUrl(
      location,
      project.name,
      base.base,
      vuln.name,
      office.name,
      customer.customer_name,
      infSystem.name,
    ),
    [
      project.name,
      base.base,
      vuln.name,
      office.name,
      customer.customer_name,
      infSystem.name,
    ])


  useEffect(() => {
    if (locationList[0]?.value === 'projects' && locationList[1] && base) {
      dispatch(getBaseObjects({ id: locationList[1].value }))
    }
    if (locationList[0]?.value === 'customers' && locationList[1] && customer) {
      dispatch(getCustomerById(locationList[1].value))
    }
  }, [])

  return (
    <div className={cn(style['LocationPath'])}>
      {
        locationList?.map((path, index) => (
          <>
            <NavLink className={cn(style['LocationPath-link'])} to={path?.url}>{path?.name}</NavLink>
            {index < locationList?.length - 1 ? <span> {' > '} </span> : ''}
          </>
        ))}
    </div>
  )
};

