
export enum ROUTES {
  COMMON = '/',
  LOGIN = 'login',
  SETTINGS = 'settings',
  DASHBOARD = 'dashboard',
  ANALYTICS = 'analytics',
  PROJECTS = 'projects',
  OBJECTS = 'objects/',
  VULNS = 'vulns',
  CUSTOMERS = 'customers',
  CUSTOMER = 'customers/',
  INF_SYSTEMS = 'infSystems',
  KILLCHAINS = 'killchains',
  OFFICES = 'offices',
  TEMPLATES = 'templates',
  REPORT = "report"
}

export enum ROUTES_OBJECT {
  WEB_APP = 'web_app',
  API = 'api',
  MOBILE_APP = 'mobile_app',
  NETWORK_DEVICE = 'network_device',
  SERVER = 'server',
  ARM = 'arm',
  WIFI = 'wifi',
  SOCIAL_ENGINEERING = 'social_engineering',
  DESKTOP_APP = 'desktop_app',
  SOURCE_CODE = 'source_code',
  EXTERNAL = 'external_ip',
  INTERNAL = 'internal_ip',
  OTHER = 'other',
}
