import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { isError } from "../storeHelpers"

import { BASE_URI } from "../../constants/auth"

import { IClientReportDataFilter, IClientReportDataTable, IClientState } from "./clientTypes"
import {
  getClientReportData,
  getClientReportLink,
} from "./clientAsync"
import { prepareObjectTypeToRu } from "../../constants/objects"
import { prepareRiskLevelToRu } from "../../constants/vulns"


const initialState: IClientState = {
  reportsData: null,
  reportsDataTable: null,
  reportUrl: null,
  isLoading: false,
  count: 0,
  error: "",
  status: 0,
  filters: null
}

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    resetUrl(state) {
      state.reportUrl = null
    },
    setFilterData(state, action: PayloadAction<IClientReportDataFilter> ){
      state.filters = action.payload
    },
    resetFilterData(state){
      state.filters = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientReportData.pending, (state) => {
        state.isLoading = false
        state.reportsData = null
        state.count = 0
      })
      .addCase(getClientReportData.fulfilled, (state, action) => {
        state.isLoading = false
        state.reportsData = action.payload.data
        state.count = action.payload.count

        const tableData: IClientReportDataTable[] = action.payload.data.map(el => (
          {
            id: el.id,
            identifier: el.identifier,
            identifier_additional: el.identifier_additional,
            object_type: prepareObjectTypeToRu[el.object_type],
            group_name: el.group_name,
            product: el.product,
            project_name: el.project_name,
            vuln_name: el.vuln_name,
            cvss_score: el.cvss_score,
            risk_level: prepareRiskLevelToRu[el.risk_level],
            count_projects: el.count_projects,
          }
        ))
        state.reportsDataTable = tableData

      })
      .addCase(getClientReportLink.pending, (state) => {
        state.reportUrl = null

      })
      .addCase(getClientReportLink.fulfilled, (state, action) => {
        state.reportUrl = BASE_URI + "/" + action.payload.msg
      })
      .addMatcher(isError, (state, action) => {
        state.error = action.payload
        state.status = action.payload
        state.isLoading = false
      })
  },
})

export const {
  resetUrl,
  setFilterData,
  resetFilterData
} = clientSlice.actions

export default clientSlice.reducer
