import { ChangeEvent, FC, useCallback, useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"

import FileSaver from "file-saver"

import Loader from "../../components/loader/Loader"
import Filters from "../../components/filters/Filters"
import Navbar from "../../components/navbar/Navbar"
import styles from "../Items.module.scss"

import {
  getNextSortParam,
  getSortString,
  sortListType,
  TableComponent,
} from "../../shared"
import { useAppDispatch } from "../../hooks/useAppDispatch"
import {
  getClientReportData,
  getClientReportLink,
} from "../../store/client/clientAsync"
import { DEFAULT_OFFSET_PAGINATION } from "../../constants/pages"
import { useAppSelector } from "../../hooks/useAppSelector"
import { selectClient } from "../../store/client/clientSelectors"
import { getDefaultSortParams } from "../../shared/lib"
import { localization } from "../../localization/localization"
import { InputTypeEnum } from "../../components/input/InputTypes"
import { objectTypeList, workTypeList } from "../../constants/objects"
import { IClientReportDataFilter } from "../../store/client/clientTypes"
import { getInfSystems } from "../../store/infSystems/infSystemsAsync"
import { getOffices } from "../../store/offices/officesAsync"
import { selectInfSystems } from "../../store/infSystems/infSystemsSelectors"
import { selectOffices } from "../../store/offices/officesSelectors"
import { selectProfileData } from "../../store/auth/authSelectors"
import Button from "../../components/button/Button"
import { TextVariantEnum } from "../../components/text/TextTypes"

import {
  resetFilterData,
  resetUrl,
  setFilterData,
} from "../../store/client/clientSlice"
import {
  attackerModellList,
  negativeConsequencesNewTypes,
  riskLevelList,
} from "../../constants/vulns"
import PaginationV2 from "../../shared/ui/PaginationV2/PaginationV2"

const ClientReportPage: FC = () => {
  const dispatch = useAppDispatch()
  const { reportsDataTable, count, isLoading, reportUrl, filters } =
    useAppSelector(selectClient)
  const { infSystems } = useAppSelector(selectInfSystems)
  const { offices } = useAppSelector(selectOffices)
  const { customer_id } = useAppSelector(selectProfileData)

  const headersObjectsRow = {
    identifier: { label: "Идентификатор" },
    identifier_additional: { label: "Идентификатор доп." },
    object_type: { label: "Тип объекта" },
    group_name: { label: "Инф.Система/Офис" },
    product: { label: "Продукт" },
    project_name: { label: "Проект" },
    vuln_name: { label: "Уязвимость" },
    cvss_score: { label: "Рейтинг уязвимости" },
    risk_level: { label: "Уровень риска" },
    count_projects: { label: "Количество анализов" },
  }

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: DEFAULT_OFFSET_PAGINATION,
  })
  const [sortParams, setSortParams] = useState<sortListType>(
    getDefaultSortParams(headersObjectsRow)
  )
  const [objectTypes, setObjectTypes] = useState<
    {
      label: string
      value: string
    }[]
  >([])

  const [riskLevel, setRiskLevel] = useState<
    {
      label: string
      value: string
    }[]
  >([])

  const [officesItems, setOfficesItems] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [infSystemsItems, setInfSystemsItems] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [workTypesItems, setWorkTypesItems] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [attackerModelItems, setAttackerModelItems] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [negativeConsequences, setNegativeConsequences] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [containsVuln, setContainsVuln] = useState<{
    label: string
    value: string
  }>({
    label: "все",
    value: "all",
  })

  const [filterItems, setFilterItems] = useState<any[]>([])
  const [ipAddresses, setIpAddresses] = useState<{
    ip_address_1: string
    ip_address_2: string
  }>({
    ip_address_1: "",
    ip_address_2: "",
  })
  const [search, setSearch] = useState<string>("")
  const [product, setProduct] = useState<string>("")

  const [isBlackboxFilter, setIsBlackboxFilter] = useState<boolean>(false)
  const [isGraykboxFilter, setIsGrayboxFilter] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const onStartDateChange = (date: any) => {
    setStartDate(date)
  }

  const onSecondaryStartDateChange = (date: any) => {
    setEndDate(date)
  }

  const onChangePagination = (offset?: number, limit?: number) => {
    const newPagination = pagination
    if (limit) {
      newPagination.limit = limit
    } else if (limit === undefined && offset !== undefined) {
      newPagination.offset = offset
    }

    setPagination(newPagination)
    const sortString = getSortString(sortParams)
    dispatch(
      getClientReportData({
        pagination: newPagination,
        filters: filters || undefined,
        sort_by: sortString,
      })
    )
  }

  useEffect(() => {
    if (customer_id) {
      dispatch(getInfSystems({ id: customer_id }))
      dispatch(getOffices({ id: customer_id }))
    }
  }, [dispatch, customer_id])

  useEffect(() => {
    const newPagination = {
      offset: 0,
      limit: DEFAULT_OFFSET_PAGINATION,
    }
    setPagination(newPagination)
    setSortParams(getDefaultSortParams(headersObjectsRow))
    dispatch(
      getClientReportData({
        pagination: {
          offset: 0,
          limit: DEFAULT_OFFSET_PAGINATION,
        },
        filters: filters || undefined,
      })
    )
  }, [dispatch, filters])

  useEffect(() => {
    setPagination({
      offset: 0,
      limit: DEFAULT_OFFSET_PAGINATION,
    })
  }, [filters])

  const [cvssScore, setCvssScore] = useState<{
    firstScore: number
    secondScore: number
  }>({ firstScore: 0, secondScore: 10 })

  const onRiskLevelChange = useCallback((data: any) => {
    setRiskLevel(data)
  }, [])

  const onNegativeConsequencesChange = useCallback((data: any) => {
    setNegativeConsequences(data)
  }, [])

  const onAttackerModelChange = useCallback((data: any) => {
    setAttackerModelItems(data)
  }, [])

  const [countProject, setCountProject] = useState<string | null>(null)

  const handleClickSort = (param: string) => {
    const newSortParams = getNextSortParam(param, sortParams)
    setSortParams(newSortParams)
    dispatch(
      getClientReportData({
        pagination,
        filters: filters || undefined,
        sort_by: getSortString(newSortParams),
      })
    )
  }
  function formatDate(date: Date | null) {
    if (date === null) return undefined
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, "0")

    return `${year}-${month}-${day}`
  }
  useEffect(() => {
    const prepareInfSystems = infSystems?.map((el) => ({
      value: el.id,
      label: el.name,
    }))
    const prepareOffices = offices?.map((el) => ({
      value: el.id,
      label: el.name,
    }))

    const containsVulnItems = [
      { value: "all", label: "все" },
      { value: "contain", label: "содержит" },
      { value: "notContain", label: "не содержит" },
    ]
    const currentFilterItems = [
      {
        id: 1,
        text: "Поиск:",
        placeholder: "Поиск",
        value: search,
        type: InputTypeEnum.Text,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          setSearch(event.target.value)
        },
      },
      {
        id: 2,
        text: "ip адрес от:",
        placeholder: "ip адрес",
        type: InputTypeEnum.Text,
        value: ipAddresses.ip_address_1,
        onChange: (event: ChangeEvent<HTMLInputElement>) =>
          setIpAddresses({
            ...ipAddresses,
            ip_address_1: event.target.value,
          }),
      },
      {
        id: 3,
        text: "ip адрес до:",
        placeholder: "ip адрес",
        type: InputTypeEnum.Text,
        value: ipAddresses.ip_address_2,
        onChange: (event: ChangeEvent<HTMLInputElement>) =>
          setIpAddresses({
            ...ipAddresses,
            ip_address_2: event.target.value,
          }),
      },
      {
        id: 4,
        text: "Дата проведения анализа:",
        type: InputTypeEnum.Date,
        date: startDate,
        onDateChange: onStartDateChange,
        secondaryValue: endDate,
        onSecondaryChange: onSecondaryStartDateChange,
        primaryText: localization.common.primaryDateTextHelper,
        secondaryText: localization.common.secondaryDateTextHelper,
      },
      {
        id: 5,
        text: localization.vuln.filters.cvssScoreText,
        placeholder: localization.vuln.filters.cvssScorePlaceholder,
        type: InputTypeEnum.Number,
        value: cvssScore.firstScore,
        onChange: (event: ChangeEvent<HTMLInputElement>) =>
          setCvssScore({
            ...cvssScore,
            firstScore: parseInt(event.target.value),
          }),
        secondaryValue: cvssScore.secondScore,
        onSecondaryChange: (event: ChangeEvent<HTMLInputElement>) => {
          setCvssScore({
            ...cvssScore,
            secondScore: parseInt(event.target.value),
          })
        },
        primaryText: localization.common.primaryNumberTextHelper,
        secondaryText: localization.common.secondaryNumberTextHelper,
      },
      {
        id: 6,
        text: localization.vuln.filters.riskLevelText,
        placeholder: localization.vuln.filters.riskLevelPlaceholder,
        value: riskLevel,
        onSelectChange: onRiskLevelChange,
        options: riskLevelList,
        isMulti: true,
      },
      {
        id: 7,
        text: localization.vuln.filters.negativeConsequencesText,
        placeholder: localization.vuln.filters.negativeConsequencesPlaceholder,
        value: negativeConsequences,
        onSelectChange: onNegativeConsequencesChange,
        options: negativeConsequencesNewTypes,
        isMulti: true,
      },
      {
        id: 8,
        text: localization.object.filters.objectTypeText,
        placeholder: localization.object.filters.objectTypePlaceholder,
        value: objectTypes,
        onSelectChange: (data: any) => {
          setObjectTypes(data)
        },
        options: objectTypeList,
        isMulti: true,
      },
      {
        id: 9,
        text: localization.object.filters.infSystemText,
        placeholder: localization.object.filters.infSystemPlaceholder,
        value: infSystemsItems,
        onSelectChange: (data: any) => {
          setInfSystemsItems(data)
        },
        options: prepareInfSystems,
        isMulti: true,
      },
      {
        id: 10,
        text: localization.object.filters.officeText,
        placeholder: localization.object.filters.officePlaceholder,
        value: officesItems,
        onSelectChange: (data: any) => {
          setOfficesItems(data)
        },
        options: prepareOffices,
        isMulti: true,
      },
      {
        id: 11,
        text: "Продукт:",
        placeholder: "Поиск по продукту...",
        type: InputTypeEnum.Text,
        value: product,
        onChange: (event: ChangeEvent<HTMLInputElement>) =>
          setProduct(event.target.value),
      },
      {
        id: 12,
        text: "Количество анализов:",
        placeholder: "Поиск по количеству...",
        type: InputTypeEnum.Number,
        value: countProject,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          setCountProject(event.target.value)
        },
      },
      {
        id: 13,
        text: "Модель атакующего:",
        placeholder: "Поиск по модели...",
        value: attackerModelItems,
        onSelectChange: onAttackerModelChange,
        options: attackerModellList,
        isMulti: true,
      },
      {
        id: 14,
        text: "Тип работ:",
        placeholder: "Поиск по типу...",
        value: workTypesItems,
        onSelectChange: (data: any) => {
          setWorkTypesItems(data)
        },
        options: workTypeList,
        isMulti: true,
      },
      {
        id: 15,
        text: "Содержит уязвимости:",
        placeholder: localization.object.filters.officePlaceholder,
        value: containsVuln,
        onSelectChange: (data: any) => {
          setContainsVuln(data)
        },
        options: containsVulnItems,
      },
      {
        id: 16,
        text: "Blackbox:",
        type: InputTypeEnum.Checkbox,
        value: isBlackboxFilter,
        onChange: (event: ChangeEvent<HTMLInputElement>) =>
          setIsBlackboxFilter(event.target.checked),
      },
      {
        id: 17,
        text: "Graybox:",
        type: InputTypeEnum.Checkbox,
        value: isGraykboxFilter,
        onChange: (event: ChangeEvent<HTMLInputElement>) =>
          setIsGrayboxFilter(event.target.checked),
      },
    ]

    setFilterItems(currentFilterItems)
  }, [
    infSystems,
    offices,
    infSystemsItems,
    objectTypes,
    officesItems,
    ipAddresses,
    search,
    cvssScore,
    negativeConsequences,
    onNegativeConsequencesChange,
    riskLevel,
    onRiskLevelChange,
    product,
    attackerModelItems,
    onAttackerModelChange,
    workTypesItems,
    isBlackboxFilter,
    isGraykboxFilter,
    startDate,
    endDate,
    containsVuln,
    countProject,
  ])

  const onSearchButtonClick = (reset: boolean) => {
    if (reset) {
      setSearch("")
      setIpAddresses({
        ip_address_1: "",
        ip_address_2: "",
      })
      setCvssScore({
        firstScore: 0,
        secondScore: 10,
      })
      setRiskLevel([])
      setObjectTypes([])
      setInfSystemsItems([])
      setOfficesItems([])
      setProduct("")
      setIsBlackboxFilter(false)
      setIsGrayboxFilter(false)
      setAttackerModelItems([])
      setWorkTypesItems([])
      setContainsVuln({
        label: "все",
        value: "all",
      })
      setStartDate(null)
      setEndDate(null)
      setCountProject("")
      dispatch(resetFilterData())
    }

    if (!reset) {
      const cvssScores = []
      if (cvssScore.firstScore > 0 || cvssScore.secondScore < 10) {
        for (
          let i = cvssScore.firstScore;
          i <= cvssScore.secondScore;
          i += 0.1
        ) {
          cvssScores.push(i.toFixed(1))
        }
      }

      const parseCountProject = countProject
        ? Number.parseInt(countProject)
        : null

      const currentFilters: IClientReportDataFilter = {
        search: search || null,
        ip_address_1: ipAddresses.ip_address_1 || null,
        ip_address_2: ipAddresses.ip_address_2 || null,
        start_date: formatDate(startDate) || null,
        end_date: formatDate(endDate) || null,
        cvss_score: cvssScores || null,
        inf_system_id: infSystemsItems?.map((el) => el.value),
        object_type: objectTypes?.map((el) => el.value) || null,
        office_id: officesItems?.map((el) => el.value) || null,
        risk_level: riskLevel?.map((el) => el.value) || null,
        negative_consequences:
          negativeConsequences.map((el) => el.value) || null,
        product: product || null,
        blackbox: isBlackboxFilter || null,
        greybox: isGraykboxFilter || null,
        attacker_model: attackerModelItems.map((el) => el.value) || null,
        work_type: workTypesItems.map((el) => el.value) || null,
        count_projects: parseCountProject,
        presence_vuln:
          containsVuln.value === "contain"
            ? true
            : containsVuln.value === "notContain"
              ? false
              : null,
      }
      dispatch(setFilterData(currentFilters))
    }
  }
  const navigate = useNavigate()

  const handleReportClick = () => {
    const cvssScores = []
    if (cvssScore.firstScore > 0 || cvssScore.secondScore < 10) {
      for (let i = cvssScore.firstScore; i <= cvssScore.secondScore; i += 0.1) {
        cvssScores.push(i.toFixed(1))
      }
    }
    const parseCountProject = countProject
      ? Number.parseInt(countProject)
      : null

    const currentFilters: IClientReportDataFilter = {
      search: search || null,
      ip_address_1: ipAddresses.ip_address_1 || null,
      ip_address_2: ipAddresses.ip_address_2 || null,
      start_date: formatDate(startDate) || null,
      end_date: formatDate(endDate) || null,
      cvss_score: cvssScores || null,
      inf_system_id: infSystemsItems?.map((el) => el.value),
      object_type: objectTypes?.map((el) => el.value) || null,
      office_id: officesItems?.map((el) => el.value) || null,
      risk_level: riskLevel?.map((el) => el.value) || null,
      negative_consequences: negativeConsequences.map((el) => el.value) || null,
      product: product || null,
      blackbox: isBlackboxFilter || null,
      greybox: isGraykboxFilter || null,
      attacker_model: attackerModelItems.map((el) => el.value) || null,
      work_type: workTypesItems.map((el) => el.value) || null,
      count_projects: parseCountProject,
      presence_vuln:
        containsVuln.value === "contain"
          ? true
          : containsVuln.value === "notContain"
            ? false
            : null,
    }

    dispatch(
      getClientReportLink({
        filters: currentFilters,
      })
    )
  }

  useEffect(() => {
    if (reportUrl) {
      FileSaver.saveAs(reportUrl)
      dispatch(resetUrl())
    }
  }, [dispatch, reportUrl])

  return (
    <>
      <Navbar />
      <div className={styles.items}>
        <div className={styles["items-content"]}>
          <Filters
            filters={filterItems}
            onSearchButtonClick={onSearchButtonClick}
          />
          <div className={styles["items-departures"]}>
            <Button
              onClick={() => navigate(-1)}
              buttonText={localization.common.backButtonText}
              typeButtonText={TextVariantEnum.S}
            />
            <Button
              onClick={handleReportClick}
              buttonText="Сформировать отчет"
              typeButtonText={TextVariantEnum.S}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={styles["items-table-wrapper"]}>
            {reportsDataTable && (
              <TableComponent
                sortParams={sortParams}
                headersRow={headersObjectsRow}
                handleClickSort={handleClickSort}
                dataList={reportsDataTable}
                isNotLink
              />
            )}
          </div>
        )}
        <PaginationV2
          count={count}
          setPagination={onChangePagination}
          pagination={pagination}
        />
      </div>
    </>
  )
}

export default ClientReportPage
