import { FC, useEffect, useMemo, useState } from "react"

import classNames from "classnames"

import { localization } from "../../../localization/localization"

import styles from "./Pagination.module.scss"
import { IPaginationProps } from "./PaginationV2Types"

const PaginationV2: FC<IPaginationProps> = ({
  setPagination,
  count = 0,
  pagination,
}) => {
  const { offset, limit } = pagination
  const [pageNumbersItem, setPageNumbersItem] = useState<number[]>([])

  const maxPage = useMemo(() => Math.ceil(count / limit), [limit, count])
  const currentPage = useMemo(
    () => Math.ceil((offset + limit) / limit),
    [offset, limit]
  )

  useEffect(() => {
    let newPageItems: number[]

    if (maxPage > 5) {
      const middlePage =
        currentPage - 2 <= 0
          ? 3
          : currentPage + 2 > maxPage
            ? maxPage - 2
            : currentPage
      newPageItems = [
        middlePage - 2,
        middlePage - 1,
        middlePage,
        middlePage + 1,
        middlePage + 2,
      ]
    } else {
      newPageItems = Array.from(
        { length: maxPage <= 5 ? maxPage : 5 },
        (_, idx) => idx + 1
      )
    }
    setPageNumbersItem(newPageItems)
  }, [currentPage, maxPage])

  const onLimitChangeHandler = (event: any) => {
    const actualLimit = parseInt(event.target.value)
    setPagination(0, actualLimit)
  }

  const onPageClickHandler = (pageNumber: number) => {
    if (pageNumber <= maxPage && pageNumber > 0) {
      setPagination(limit * (pageNumber - 1))
    }
  }

  return (
    <div className={styles["pagination"]}>
      <div>
        <span className={styles["pagination-total"]}>
          {localization.pagination.amount}
        </span>
        <select
          className={styles["pagination-select"]}
          value={pagination.limit}
          onChange={onLimitChangeHandler}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </select>
      </div>
      <div className={styles["pagination-additional"]}>
        <span
          className={styles["pagination-total"]}
        >{`${localization.pagination.allTitle} ${count}`}</span>
        <img
          onClick={() => onPageClickHandler(currentPage - 1)}
          src="/assets/icons/arrow-left.svg"
          alt={localization.pagination.arrowLeftAlt}
          className={styles["pagination-arrow"]}
        />
        {pageNumbersItem.map((pageNumber, idx) => (
          <span
            key={idx}
            onClick={() => {
              currentPage !== pageNumber && onPageClickHandler(pageNumber)
            }}
            className={classNames(styles["pagination-number"], {
              [styles["pagination-number_blue"]]: currentPage === pageNumber,
            })}
          >
            {pageNumber}
          </span>
        ))}
        <img
          onClick={() => onPageClickHandler(currentPage + 1)}
          src="/assets/icons/arrow-right.svg"
          alt={localization.pagination.arrowRightAlt}
          className={styles["pagination-arrow"]}
        />
        <span className={styles["pagination-total"]}>{`из ${maxPage}`}</span>
      </div>
    </div>
  )
}

export default PaginationV2
