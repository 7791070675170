import { localization } from "../localization/localization"

export const INF_SYSTEMS_URL = "inf_systems"

export const infSystemHeadCells = [
  {
    id: "checked",
    checked: true,
    image: false,
  },
  {
    id: "name",
    text: "Наименование ИС",
  },
  {
    id: "web_interface_address",
    text: "Адрес Web-интерфейса",
  },
  {
    id: "security_level",
    text: "Уровень защищенности",
  },
  {
    id: "product",
    text: "Продукт",
  },
  {
    id: "product_manager",
    text: localization.infSystem.filters.productManagerText,
  },
  {
    id: "inf_system_contact_person",
    text: localization.infSystem.filters.contactPersonText,
  },
]

export const infSystemHeaderTable = {
  name: { label: "Наименование ИС" },
  web_interface_address: { label: "Адрес Web-интерфейса" },
  security_level: { label: "Уровень защищенности" },
  product: { label: "Продукт" },
  product_manager: { label: localization.infSystem.filters.productManagerText },
  inf_system_contact_person: { label: localization.infSystem.filters.contactPersonText },
}

export const infSystemSecurityLevelPopupItems = [
  {
    value: "critical",
    text: "Критический",
    id: 1,
  },
  {
    value: "high",
    text: "Высокий",
    id: 2,
  },
  {
    value: "medium",
    text: "Средний",
    id: 3,
  },
  {
    value: "low",
    text: "Низкий",
    id: 4,
  },
]

export const prepareSecurityLevelToRu: { [index: string]: string } = {
  "critical": "Критический",
  "high": "Высокий",
  "medium": "Средний",
  "low": "Низкий",
}

export const prepareSecurityLevelToEng: { [index: string]: string } = {
  "Критический": "critical",
  "Высокий": "high",
  "Средний": "medium",
  "Низкий": "low",
}

export const securityLevels = [
  { value: "critical", label: "Критический" },
  { value: "high", label: "Высокий" },
  { value: "medium", label: "Средний" },
  { value: "low", label: "Низкий" },
]
