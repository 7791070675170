import { createAsyncThunk } from "@reduxjs/toolkit"

import { IThunkApiConfigProps } from "../storeTypes"


import { getQueryParams } from "../utils/getQueryParams"

import { IClientDataRequest, IClientReportDataResponse, IClientReportResponse } from "./clientTypes"


export const getClientReportData = createAsyncThunk<IClientReportDataResponse, IClientDataRequest, IThunkApiConfigProps>(
  "client/getObjects",
  async (queryParams, { rejectWithValue, extra }) => {
    try {
      const { filters, pagination, sort_by } = queryParams
      let presenceVuln = ""
      if (filters && filters?.presence_vuln !== null){
        presenceVuln = `&presence_vuln=${filters.presence_vuln}`
        
      }
      const f = {...filters, presence_vuln: null}

      const queries = getQueryParams(pagination,f, sort_by)

      
      const response = await extra.api.get(
        "client/data" + queries + presenceVuln ,
      )
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response.status)
    }
  },
)
export const getClientReportLink = createAsyncThunk<{ msg: string }, IClientReportResponse, IThunkApiConfigProps>(
  "client/getObjectsReport",
  async (queryParams, { rejectWithValue, extra }) => {
    try {
      const { filters } = queryParams
      let presenceVuln = ""
      const f = {...filters, presence_vuln: null}
      const queries = getQueryParams(undefined, f, undefined)
      if (filters && filters?.presence_vuln !== null){
        const prefix = queries === "" ? "?" : "&"
        presenceVuln = `${prefix}presence_vuln=${filters.presence_vuln}`
        
      }
      
      const response = await extra.api.get(
        "client/data/generate_report" + queries + presenceVuln,
      )
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response.status)
    }
  },
)

